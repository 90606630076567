import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function Qr() {
  return (
    <StaticImage
      src="../../images/qr.png"
      alt=""
      layout="fullWidth"
      placeholder="blurred"
      formats={[ 'auto', 'webp', 'avif' ]}
    />
  )
}
