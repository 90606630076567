import React from 'react'
import Layout from '../components/layout'
import PageTitle from '../components/page-title'
import Heading from '../components/heading'
import Qr from '../components/images/qr'
import SEO from '../components/seo'

export default function Contact() {
  const title = {
    jp: 'お問い合わせ',
    en: 'CONTACT'
  }

  const heading = 'お問い合わせ'

  const qr = '当社へのお問い合わせ先は以下をご覧ください。'

  const phone = {
    heading: 'お電話でのご相談・お見積もりのご依頼は下記の番号よりご連絡ください。',
    label: 'Tel.',
    number: '042-444-5796',
    numberLink: '0424445796',
    note: 'お問い合わせの際には、電話番号をよくお確かめのうえ、お掛け間違いのないよう、お願い申し上げます。'
  }

  const fax = {
    label: 'Fax.',
    number: '042-444-5797',
    buttonLabel: 'サンリクFAX注文書',
    buttonURL: '/pdf/order-fax.pdf'
  }

  const email = {
    heading: 'E-mailでのお問い合わせは下記のアドレスへ',
    address: 'info@sanriku.info',
    note: '※<a href="" class="underline">個人情報保護方針</a>について同意いただいた上でご送信ください。'
  }

  return (
    <Layout>
      <SEO title="お問い合わせ | 株式会社サンリク" />
      <PageTitle titleJP={title.jp} titleEN={title.en} />
      <div className="max-w-screen-lg mx-auto mt-20 px-6 md:px-0">
        <Heading heading={heading} />

        <p className="md:text-center mt-10 font-medium text-lg">{qr}</p>
        <div className="w-48 mx-auto mt-10">
          <Qr />
        </div>

        <div className="md:text-center mt-20 border-t border-gray-400 py-10">
          <p className="font-medium text-lg">{phone.heading}</p>
          <div className="flex items-center justify-center text-4xl md:text-5xl font-bold font-crimson mt-6">
            <div>{phone.label}</div>
            <a href={`tel:${phone.numberLink}`} className="block">{phone.number}</a>
          </div>
          <p
            dangerouslySetInnerHTML={{ __html: phone.note }}
            className="mt-6"
          />
        </div>

        <div className="border-t border-gray-400 py-10">
          <div className="flex items-center justify-center text-4xl md:text-5xl font-bold font-crimson">
            <div>{fax.label}</div>
            <div className="block">{fax.number}</div>
          </div>
          <a
            href={fax.buttonURL}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-red-700 text-white block mx-auto text-xl font-bold w-80 text-center py-3 tracking-widest mt-6 rounded-lg shadow-md"
          >
            {fax.buttonLabel}
          </a>
        </div>

        <div className="border-t border-gray-400 py-10 text-center">
          <p className="font-medium text-lg">{email.heading}</p>
          <a href={`mailto:${email.address}`}
             className="mt-6 text-4xl md:text-5xl font-crimson font-bold mt-6 block">{email.address}</a>
          <p dangerouslySetInnerHTML={{ __html: email.note }} className="mt-6" />
        </div>
      </div>
    </Layout>
  )
}
